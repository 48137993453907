import PropTypes from 'prop-types';
import { ManCard, ManText } from '@craft-design-system/core-components-react';
import PopoverComponent from '../popover';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
// import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
import { Card } from 'react-bootstrap';

const Installation_energy = ({ value }) => {
  const [installation_energy, set_installation_energy] = useState(null);

  console.log('test',value)








  useEffect(() => {
    try {
        set_installation_energy(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
<Card className='card_battery' >      
<ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Installed Capacity
      </ManText>
      <PopoverComponent id= 'median_consumption' content='This KPI states the installed capacity on the vehicle. The value is taken from the vehicle configuration.

If more than one vehicle is selected the distinct number of vehicles per configuration option is displayed.' />


      <div>
      {installation_energy && installation_energy.data ? (
       <Plot 
       data={installation_energy.data} 
       layout={{
           ...installation_energy.layout,
           autosize: true,
           responsive: true,
           margin: { t: 10, r: 10, l: 10, b: 10 }
       }}
       style={{ width: '100%', height: '100%' }}
       useResizeHandler={true}
   />
      ) : (
        <div>
        <ManText variant={"heading-s"} as={"span"}>{value}</ManText>
        </div>
      )}
    </div>      
      
      </Card>
  );
};

Installation_energy.propTypes = {
  value: PropTypes.string.isRequired,
};
export default Installation_energy;