import PropTypes from 'prop-types';
import { ManCard, ManText } from '@craft-design-system/core-components-react';
import PopoverComponent from '../popover';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
// import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
import { Card } from 'react-bootstrap';


const No_of_packs = ({ value }) => {
  const [no_of_packs, set_no_of_packs] = useState(null);

  console.log('packs',value)








  useEffect(() => {
    try {
        set_no_of_packs(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <Card className='card_battery' >      

      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Number of packs
      </ManText>
      <PopoverComponent id= 'median_consumption' content='This KPI states the installed number of battery packs on the vehicle. The value is taken from the vehicle configuration.

If more than one vehicle is selected the distinct number of vehicles per configuration option is displayed.' />


      {/* <ManText variant={"heading-s"} as={"span"}>{value}</ManText> */}
      <div >
      {no_of_packs && no_of_packs.data ? (
        <Plot 
        data={no_of_packs.data} 
        layout={{
            ...no_of_packs.layout,
            autosize: true,
            responsive: true,
            margin: { t: 10, r: 10, l: 10, b: 10 }
        }}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
    />
      ) : (
        <div>
        <ManText variant={"heading-s"} as={"span"}>{value}</ManText>
        </div>
      )}
    </div>
      </Card>
  );
};

No_of_packs.propTypes = {
  value: PropTypes.string.isRequired,
};

export default No_of_packs;