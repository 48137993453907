import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const boldStyle = {
  fontWeight: 'bold'
};
const Charging_mode = ({ value }) => {
  const [Charging_mode, set_charging_mode] = useState(null);
  

//   //console.log('test',value)

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      This KPI displays the total number of charging cycles per charging mode since start of data acquisition. The two available charging modes are immediate charging and timer charging.<br/>
      <ul>
      <li><span style={boldStyle}>Immediate charging</span>: Vehicle is plugged and charging starts immediately.<br/></li>
      <li><span style={boldStyle}>Timer charging</span>: Vehicle is plugged and charging starts after defined time (e.g. to finalize charging at certain point in time).</li><br/>
      <li><span style={boldStyle}>Other charging</span>: Charging mode is unknown.<br/></li>
      </ul>
      If more than one vehicle is selected the total number of charging cycles per charging mode is displayed (sum).
            </Popover.Body>
    </Popover>
  );


  useEffect(() => {
    try {
        set_charging_mode(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Charging Mode
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>

      {Charging_mode && Charging_mode.data && Charging_mode.data.length > 0 
        ? <Plot 
        data={Charging_mode.data} 
        layout={{
            ...Charging_mode.layout,
            autosize: true,
            responsive: true,
            margin: { t: 10, r: 10, l: 10, b: 10 }
        }}
        style={{ width: '100%', height: '85%' }}
        useResizeHandler={true}
    />
        : <ManText className="title" variant="body-l" as="span">
          No Data available for Trucks
        </ManText>
      }
    </ManCard>
  );
};

Charging_mode.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Charging_mode;