import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import newFeatureImage from '../assets/images/New_features_images/image.png';
import TotalMileageImage from '../assets/images/New_features_images/Total_mileage.png';
import ScatterplotImage from '../assets/images/New_features_images/scatterplot.PNG';
import fleetConfigImage from '../assets/images/New_features_images/fleet.png';
const NewFeaturesModal = () => {
  const [showModal, setShowModal] = useState(false);

  // Use localStorage to show the modal only once
  useEffect(() => {
    const isFirstVisit2 = localStorage.getItem('firstVisit2');
    if (!isFirstVisit2) {
      setShowModal(true);
      localStorage.setItem('firstVisit2', 'true');
    }
  }, []);

  const handleClose = () => setShowModal(false);

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
  <Modal.Header closeButton>
    <Modal.Title>Release 1.2 | Enhancements & Bug Fixes</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <h6>1. Heatmaps replace scatterplots</h6>
    <p>
    Until now scatterplots have been in place for the KPIs Efficiency and Charging Power vs. Battery Temperature. We decided to move to heatmap as it facilitates to read the KPIs - this was also suggested by your valuable user feedback!
    </p>
    <img src={ScatterplotImage} alt="Heatmap Visual Description" className="modal-image" style={{ width: '100%' }} />
    <br /><br />

    {/* Section 2 */}
    <h6>2. KPI "Charging Mode" activated for busses (battery type "BBP")</h6>
    <p>
      The KPI <em>Charging Mode</em> is now available for buses with battery type <strong>BBP</strong>.
      This KPI will also be introduced for trucks (battery type <strong>CBP</strong>) in 2025.
      It displays the total number of charging cycles per mode since the start of data acquisition.
    </p>
    <ul>
      <li>
        <strong>Immediate Charging:</strong> Charging starts immediately upon plugging in.
      </li>
      <li>
        <strong>Timer Charging:</strong> Charging begins after a defined delay (e.g., to finish charging at a specific time).
      </li>
      <li>
        <strong>Other Charging:</strong> Charging mode is unknown.
      </li>
    </ul>
    <p>
      If multiple vehicles are selected, the total number of charging cycles per mode is displayed(sum).
    </p>
    <p>
      What are the root causes for "Other charging":

    <ul>
      <li>Primary reason: The charging cycle is identified via data from electric app. The information on charging mode is withdrawn from charging app. Sometimes there is no corresponding data between both sources.</li>
      <li>Secondary reason: Data from charging app is available only after the charging cycle was finished.</li>
      <li>We work on solving both root causes.</li>
    </ul>
    </p>
    <br />

    {/* Section 3 */}
    <h6>3. Configuration information for fleets enabled</h6>
    <p>
    From now on we will present a distribution on configuration information for vehicle fleets.
    </p>
    <img src={fleetConfigImage} alt="Fleet Configuration Visual Description" className="modal-image" style={{ width: '100%' }}  />
    <br /><br />

    {/* Section 4 */}
    <h6>4. Visualization of data exclusion due to data quality criteria</h6>
    <p>
    We apply a few data quality checks before presenting the data in the KPIs in dashboard. This aims at filtering out implausible data (e.g. due to jumps in cumulative vehicle signals).

    We will from now on give a note in the dashboard if we are not presenting information due to data quality criteria.
    </p>
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Data Quality Criteria</th>
          <th>KPIs Not Visualized</th>
          <th>Frontend Message</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Daily mileage &lt; 20 km</td>
          <td>Energy Throughput, Average Speed, Energy Throughput per Speed Class</td>
          <td>Mileage &lt; 20 km</td>
        </tr>
        <tr>
          <td>Energy Throughput per 100 km &gt; 380 kWh</td>
          <td>Energy Throughput, Average Speed, Energy Throughput per Speed Class</td>
          <td>Implausible energy throughput</td>
        </tr>
      </tbody>
    </table>
    <br />

    {/* Section 5 */}
    <h5>5. Bug Fixes</h5>
    <ul>
      <li>
        <strong>KPIs "SoC" and "Depth of Discharge":</strong> Temporarily disabled while calculation logic is updated.
      </li>
    </ul>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

  );
};

export default NewFeaturesModal;
