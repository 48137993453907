import logo from '../assets/images/MAN Logo.svg'
import logout from '../assets/images/exit.svg'
import {
    ManText,
    defineCustomElements,
  } from "@craft-design-system/core-components-react";
// Register global css file
import "@craft-design-system/core-components/dist/core-components/core-components.css";

defineCustomElements();




function handleHelpClick() {
  window.location.href = `mailto:d76d4c5f.m365.man@emea.teams.ms?subject=BEV Analyzer // [Add subject] &body=Please describe your problem / feedback / question here.`;
}


const Header = () => {
 return (
 <div className='headers'>
  <div className='logo-container'>
    <img className="man-logo" src={logo}/>
    <ManText className="logo-title" variant={"display-m"} as={"span"} >
      Battery Electric Vehicle Analyzer
    </ManText>

  </div>
  <div className='nav-items'>
    <div className='username'>
    {/* <ManText  variant={"heading-xs"} as={"span"} >
      Vishal Talwar
    </ManText> */}
    </div>
    <div className='logout'>

    <a href="https://confluence.collaboration-man.com/pages/viewpage.action?pageId=276549234" style={{ textDecoration: 'none' }} target="_blank">
    <button style={{ background: 'white', color: 'black', padding: '10px', border: '0px' }}>
    <ManText className="logout" variant={"heading-s"} as={"span"}>
    <span role="img" aria-label="sparkles">✨</span> New Release!

</ManText>
    </button>
  </a>



    <button style={{background:'white',border:'0px'}} onClick={handleHelpClick}>            
    <ManText className="logout" variant={"heading-s"} as={"span"}>
     Need help?
    </ManText>

    </button>
    </div>


  </div>

    </div>
 )
  
};

export default Header;