import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const SOC = ({ value }) => {
  const [soc, set_soc] = useState(null);

//   //console.log('test',value)

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      The state of charge (SoC) is displayed in relation to the mileage of the vehicle. <br/>

The SoC plot only contains discharging cycles and does not contain charging cycles. <br/>

If the engine is turned off, no data is visualized. This results in gaps in both plots.<br/>

This visualisation is only available if one individual vehicle is selected
      </Popover.Body>
    </Popover>
  );
//   //console.log('test',soc)






  useEffect(() => {
    try {
        set_soc(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      SOC
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>



      {soc && soc.data && soc.data.length > 0 
    ? <Plot data={soc.data} layout={soc.layout} />
    :<ManText className="title" variant="body-l" as="span">
    KPI will be available soon
  </ManText>}    
      </ManCard>
  );
};

SOC.propTypes = {
  value: PropTypes.string.isRequired,
};

export default SOC;